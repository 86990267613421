import React from "react"

import siteSettings from "../constants/site-settings";

import './contact-form.scss'


function ContactFormComponent() {
  return (
    <form className="contact-form grid grid-cols-1 gap-y-5" action={siteSettings.getformUrl} method="POST">
      <div className="hidden">
        <label className="flex mb-2" htmlFor="testing">Don’t fill this out if you’re human: </label>
        <input type="text"
               id="testing"
               name="testing"
               className="w-full"/>
      </div>
      <div>
        <label className="flex mb-2" htmlFor="name">Name</label>
        <input type="text"
               id="name"
               name="name"
               className="w-full"
               placeholder="Enter your name"
               required/>
      </div>
      <div>
        <label className="flex mb-2" htmlFor="email">Email</label>
        <input type="email"
               id="email"
               name="email"
               className="w-full"
               placeholder="Enter your e-mail address"
               required/>
      </div>
      <div>
        <label className="flex mb-2" htmlFor="message">Message</label>
        <textarea id="message"
                  name="message"
                  className="w-full"
                  placeholder="Type the message"
                  required/>
      </div>
      <button type="submit" className="gdb-btn primary submit">Send</button>
    </form>
  )
}

export default ContactFormComponent
