import * as React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import {Helmet} from "react-helmet";

import siteSettings from "../constants/site-settings";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import ContactFormComponent from "../components/contact-form";

import EmailSvg from "../images/icons/email-dark.svg"
import PhoneSvg from "../images/icons/phone-dark.svg"
import MarkerSvg from "../images/icons/marker-dark.svg"
import ClockSvg from "../images/icons/clock-dark.svg"
import MapPinSvg from "../images/icons/map-pin.svg"

import '../styles/contacts.scss'


const ContactsPage = ({data}) => {

  const fluidTopImgSource = [
    data.top.childImageSharp.fluid,
  ];

  const metaTitle = `${siteSettings.siteTitle} - Commercial and Residential Service in Chicagoland`;
  const metaDescription = `Trusted Garage Door Service | Overhead door Installation, Repair, Maintenance | Same Day Service ${siteSettings.phoneNumberPretty}`;

  return (
    <div className="w-full">
      <Helmet>
        <title>{metaTitle}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover"/>
        <meta name="description" content={metaDescription}/>

        <meta http-equiv="refresh" content="0;url=https://www.gdbgaragedoorservice.com/"/>

        <meta property="og:site_name" content={metaTitle}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={siteSettings.siteTitle}/>
        <meta property="og:description" content={siteSettings.siteDescription}/>
        <meta property="og:url" content={siteSettings.siteUrl}/>
        <meta property="og:type" content="Landing page"/>

        <meta property="og:image" content={siteSettings.image}/>
        <meta property="og:image:width" content={siteSettings.shareImageWidth}/>
        <meta property="og:image:height" content={siteSettings.shareImageHeight}/>

        <meta name="twitter:title" content={metaTitle}/>
        <meta name="twitter:description" content={siteSettings.siteDescription}/>
        <meta name="twitter:url" content={siteSettings.siteUrl}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content={siteSettings.image}/>

        <meta name="google-site-verification" content="_15yVB2JdnGYJioiAfQOr1Ic_rwEdE34nZIg8EKsCTk" />

        <link rel="canonical" href={siteSettings.siteUrl}/>

        <script defer data-domain="gdb-repair.com" src="https://plausible.io/js/plausible.js"/>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "GDB Garage Door",
            "image": "https://www.gdb-repair.com/main.webp",
            "@id": "",
            "url": "https://www.gdb-repair.com/",
            "telephone": "773-956-1198",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1043 S York Rd Ste",
              "addressLocality": "Bensenville",
              "addressRegion": "IL",
              "postalCode": "60106",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 41.933984,
              "longitude": -87.93830559999999
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "07:30am",
              "closes": "08:00pm"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Saturday",
                "Sunday"
              ],
              "opens": "07:30am",
              "closes": "03:30pm"
            }],
            "sameAs": [
              "https://www.facebook.com/GDB.GarageDoorRepair",
              "https://www.instagram.com/gdbgaragedoor/",
              "https://twitter.com/DoorGdb",
              "https://www.youtube.com/channel/UCK8N5Pkvm2G_RarsfRfPjYQ",
              "https://www.gdb-repair.com/"
            ] 
          })}
        </script>
      </Helmet>

      <HeaderComponent/>
      <main className="contacts">
        <div className="top-row relative">
          <Img fluid={fluidTopImgSource} objectFit="cover" className="image" alt="Wall"/>
          <div className="description mx-auto px-5 flex flex-col gap-10 justify-center items-center">
            <h1>Contacts</h1>
            <p className="text-center">Our experienced and professional team will always be on time to provide you with the best service to meet and exceed your expectations.
              Always know that you can count on us 24 hours a day for any emergency situation that arises.</p>
            <a className="gdb-btn primary flex" href={siteSettings.phoneNumberLink}>Call the specialist</a>
          </div>
        </div>
        <div className="data-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-col lg:flex-row justify-around">
              <div className="flex flex-col">
                <div className="relative">
                  <MapPinSvg/>
                  <div className="pl-10">
                    <h4 className="flex">Main Headquarters</h4>
                    <p className="mb-5">1043 S York Rd Ste. LL5A<br/>
                      Bensenville, IL 60106</p>
                  </div>
                </div>
                <div className="relative">
                  <PhoneSvg/>
                  <div className="pl-10">
                    <h4 className="mb-2">Phone</h4>
                    <p className="font-semibold">{siteSettings.phoneNumberPretty}</p>
                    <p className="font-semibold mb-5"></p>
                  </div>
                </div>
                <div className="relative">
                  <EmailSvg/>
                  <div className="pl-10">
                    <h4 className="mb-2">Email</h4>
                    <p className="mb-5">{siteSettings.email}</p>
                  </div>
                </div>
              </div>
              <div className="flex relative">
                <ClockSvg/>
                <div className="pl-10">
                  <h4 className="mb-2">Business Hours</h4>
                  <p>Mon  7:30am-8:00pm</p>
                  <p>Tue  7:30am-8:00pm</p>
                  <p>Wed  7:30am-8:00pm</p>
                  <p>Thur 7:30am-8:00pm</p>
                  <p>Fri  7:30am-8:00pm</p>
                  <p>Sat  7:30am-03:30pm</p>
                  <p>Sun  CLOSED</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-col gap-5 justify-between lg:row-span-2 lg:order-1">
              <iframe title="map" loading="lazy" allowFullScreen
                      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJS6x1eHyzD4gRqtMKwzx50y4&key=AIzaSyDHNxPRnj5ff2FV0mjfdza9-D40twMQOw4"/>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent/>
    </div>
  )
}

export default ContactsPage

export const query = graphql`
    query {
        top: file(relativePath: { eq: "contacts-top.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
